import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Button,
  Box,
  Text,
  // Checkbox,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import axios from "axios";
import { baseUrl } from "../../../apiCalls/config";
import UpdateMedicCredentialsModal from "./UpdateMedicCredentialsModal ";

const EditMedicModal = ({ isOpen, onClose, customer }) => {
  const [formData, setFormData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    gender: "",
    dob: "",
    image: "",
    language: "",
    religion: "",
    medicType: "",
    address: "",
    matchedToAppointment: "",
    walletId: "",
  });
  const [loading, setLoading] = useState(false);
 
  const [imageLoading, setImageLoading] = useState(false);
  const majorReligions = ["Christianity", "Islam", "Traditional", "Other"];
  const {
    isOpen: isCredOpen,
    onOpen: onCredOpen,
    onClose: onCredClose,
  } = useDisclosure();
  const toast = useToast();

  // const handleCheckboxChange = (e) => {
  //   const { name, checked } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: checked,
  //   }));
  // };

  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);
  const [passwordData, setPasswordData] = useState({
    phoneNumber: "",
    newPassword: "",
  });
  const [showNewPassword, setShowNewPassword] = useState(false);

  useEffect(() => {
    if (customer) {
      setFormData({
        id: customer.id || "",
        firstName: customer.bioData?.firstName || "",
        lastName: customer.bioData?.lastName || "",
        phoneNumber: customer.bioData?.phoneNumber || "",
        email: customer.bioData?.email || "",
        gender: customer.bioData?.gender || "",
        dob: customer.bioData?.dob ? customer.bioData.dob.split("T")[0] : "",
        image: customer.bioData?.image || "",
        language: customer.preferredLanguage || "",
        religion: customer.religion || "",
        address: customer.medicHomeAddress || "",
        medicType: customer?.medicType || "",
        walletId: customer?.walletId || "",
      });

      // Automatically populate the phone number in the password change modal
      setPasswordData((prev) => ({
        ...prev,
        phoneNumber: customer.bioData?.phoneNumber || "",
      }));
    }
  }, [customer]);

  const handlePasswordChange = async () => {
    setLoading(true);
    try {
        const token = localStorage.getItem("token"); // Retrieve the auth token

        if (!token) {
            toast({
                title: "Unauthorized",
                description: "No authentication token found. Please log in again.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
            });
            return;
        }

        const response = await axios.post(
            `${baseUrl}/api/admin/change-password`,
            passwordData, 
            {
                headers: {
                    Authorization: `Bearer ${token}`, 
                    "Content-Type": "application/json",
                },
            }
        );

        if (response.data.success) {
            toast({
                title: "Password changed successfully.",
                description: "User's password has been updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top-right",
            });
            setPasswordModalOpen(false);
        } else {
            toast({
                title: "Password change failed.",
                description: response.data.message || "An error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
            });
        }
    } catch (error) {
        console.error("Error changing password:", error);
        toast({
            title: "Password change failed.",
            description: "An error occurred while updating the password.",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
        });
    } finally {
        setLoading(false);
    }
};


  useEffect(() => {
    if (customer) {
      setFormData({
        id: customer.id || "",
        firstName: customer.bioData?.firstName || "",
        lastName: customer.bioData?.lastName || "",
        phoneNumber: customer.bioData?.phoneNumber || "",
        email: customer.bioData?.email || "",
        gender: customer.bioData?.gender || "",
        dob: customer.bioData?.dob ? customer.bioData.dob.split("T")[0] : "",
        image: customer.bioData?.image || "",
        language: customer.preferredLanguage || "",
        religion: customer.religion || "",
        address: customer.medicHomeAddress || "",
        startDate: customer?.appointmentStartDate || "",
        endDate: customer?.appointmentEndDate || "",
        medicType: customer?.medicType || "",
        walletId: customer?.walletId || "",
        appIdForPayment: customer?.appIdForPayment || "",
        matchedToAppointment: customer?.matchedToAppointment || "",

        maritalStatus: customer?.maritalStatus,
        spouseFullName: customer?.spouseFullName,
        spousePhoneNumber: customer?.spousePhoneNumber,
        spouseEmail: customer?.spouseEmail,
        spouseAddress: customer?.spouseAddress,
        spouseHomeAddress: customer?.spouseHomeAddress,
        guarantorFirstName: customer?.guarantorFirstName,
        guarantorLastName: customer?.guarantorLastName,
        guarantorPhone: customer?.guarantorPhone,
        guarantorEmail: customer?.guarantorEmail,
        guarantorHomeAddress: customer?.guarantorHomeAddress,
        relationshipWithGuarantor: customer?.relationshipWithGuarantor,
        lastEmployerFullName: customer?.lastEmployerFullName,
        lastEmployerPhoneNumber: customer?.lastEmployerPhoneNumber,
        lastEmployerEmail: customer?.lastEmployerEmail,
        lastEmployerAddress: customer?.lastEmployerAddress,
        medicHomeAddress: customer?.medicHomeAddress,
        yearsOfExp: customer?.yearsOfExp,
      });
    }
  }, [customer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Handle email validation in real-time
    if (name === "guarantorEmail" || name === "lastEmployerEmail") {
      if (!validateEmail(value)) {
        setEmailErrors((prevErrors) => ({
          ...prevErrors,
          [`${name}Error`]: "Invalid email format",
        }));
      } else {
        setEmailErrors((prevErrors) => ({
          ...prevErrors,
          [`${name}Error`]: "",
        }));
      }
    }
  };

  const handleImageChange = async (image) => {
    setImageLoading(true);

    if (image === undefined) {
      // toast.error("Please select an image")
      setImageLoading(false);
      return;
    }

    if (image.type === "image/jpeg" || image.type === "image/png") {
      const data = new FormData();
      data.append("file", image);
      data.append("upload_preset", "profileImage");
      data.append("cloud_name", "dmfewrwla");

      try {
        const response = await fetch(
          "https://api.cloudinary.com/v1_1/dmfewrwla/image/upload",
          {
            method: "post",
            body: data,
          }
        );

        const imageData = await response.json();

        setFormData({
          ...formData,
          image: imageData.url.toString(),
        });
        setImageLoading(false);
        console.log(imageData.url.toString());
      } catch (err) {
        console.log(err);
        setImageLoading(false);
      }
    } else {
      setImageLoading(false);
      return;
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleImageChange(file);
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    setLoading(true);

    try {
      await handleImageChange(formData.image, formData, setFormData);

      const formatDateWithDayAdjustment = (selectedDate) =>
        formatDateToUTC(new Date(selectedDate));

      const formDataWithDate = {
        ...formData,
        dob: formatDateWithDayAdjustment(formData.dob),
      };

      const response = await axios.post(
        `${baseUrl}/api/admin/updateUser`,
        formDataWithDate,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        toast({
          title: "User details updated.",
          description:
            "The user's details have been successfully updated. Kindly refresh the page to see the update.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        onClose();
      } else {
        toast({
          title: "Update failed.",
          description: "There was an error updating the user's details.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error updating user details:", error);
      toast({
        title: "Update failed.",
        description: "There was an error updating the user's details.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  const maritalStatuses = ["Single", "Married", "Divorced", "Widowed"];

  const [emailErrors, setEmailErrors] = useState({
    guarantorEmailError: "",
    lastEmployerEmailError: "",
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const formatDateToUTC = (selectedDate) => {
    if (!selectedDate) return "";

    if (isNaN(new Date(selectedDate))) {
      console.error("Invalid date:", selectedDate);
      return "";
    }

    const adjustedDate = new Date(selectedDate);
    adjustedDate.setDate(adjustedDate.getDate());

    return adjustedDate.toISOString().split("T")[0];
  };

  // const handlePasswordChange = async () => {
  //   const token = localStorage.getItem("token");
  //   if (!token) {
  //     console.error("No token found, redirecting to login.");
  //     window.location.href = "/login";
  //     return;
  //   }

  //   setLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${baseUrl}/api/admin//change-password`,
  //       passwordData,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     if (response.data.success) {
  //       toast({
  //         title: "Password changed.",
  //         description: "Your password has been successfully changed.",
  //         status: "success",
  //         duration: 5000,
  //         isClosable: true,
  //         position: "top-right",
  //       });
  //       setPasswordModalOpen(false);
  //     } else {
  //       toast({
  //         title: "Password change failed.",
  //         description: "There was an error changing your password.",
  //         status: "error",
  //         duration: 5000,
  //         isClosable: true,
  //         position: "top-right",
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error changing password:", error);
  //     toast({
  //       title: "Password change failed.",
  //       description: "There was an error changing your password.",
  //       status: "error",
  //       duration: 5000,
  //       isClosable: true,
  //       position: "top-right",
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <>
      <Modal
        style={{ zIndex: 1400 }}
        size="2xl"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent bg="#4B4B4B" color="white" maxH="500px" overflow="auto">
          <ModalHeader>Edit Profile Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="firstName" mb={3}>
              <FormLabel>First Name</FormLabel>
              <Input
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="lastName" mb={3}>
              <FormLabel>Last Name</FormLabel>
              <Input
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="phoneNumber" mb={3}>
              <FormLabel>Phone Number</FormLabel>
              <Input
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="email" mb={3}>
              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="appIdForPayment" mb={3}>
              <FormLabel>Customer Appointment Id</FormLabel>
              <Input
                name="appIdForPayment"
                value={formData.appIdForPayment}
                onChange={handleChange}
              />
            </FormControl>
            {/* <FormControl id="walletId" mb={3}>
              <FormLabel>Wallet Id</FormLabel>
              <Input
                name="walletId"
                value={formData.walletId}
                onChange={handleChange}
              />
            </FormControl> */}
             {/* <FormControl mb={3} id="isPaid">
            <FormLabel>Matched</FormLabel>
            <Checkbox
              name="matchedToAppointment"
              isChecked={formData.matchedToAppointment}
              onChange={handleCheckboxChange}
            >
              {formData.matchedToAppointment ? "Yes" : "No"}
            </Checkbox>
          </FormControl> */}

            <FormControl id="gender" mb={3}>
              <FormLabel>Gender</FormLabel>
              <Input
                name="gender"
                value={formData.gender}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="dob" mb={3}>
              <FormLabel>Date of Birth</FormLabel>
              <Input
                name="dob"
                type="date"
                value={formData.dob}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="address" mb={3}>
              <Box spacing={4} marginTop="20px" flex="1">
                <FormLabel> Religion</FormLabel>
                <Select
                  isRequired
                  name="religion"
                  color="black"
                  placeholder="Select Religion"
                  value={formData?.religion}
                  onChange={handleChange}
                >
                  {majorReligions.map((religion) => (
                    <option key={religion} value={religion}>
                      {religion}
                    </option>
                  ))}
                </Select>
              </Box>
            </FormControl>
            <FormControl id="address" mb={3}>
              <FormLabel>Address</FormLabel>
              <Input
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="language" mb={3}>
              <FormLabel>Native Language</FormLabel>
              <Input
                name="language"
                value={formData.language}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="yearsOfExp" mb={3}>
              <FormLabel>Years of Experience</FormLabel>
              <Input
                name="yearsOfExp"
                value={formData.yearsOfExp}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl mb={3} id="medicType">
              <FormLabel>Medic Type</FormLabel>
              <Select
                color="black"
                name="medicType"
                placeholder="Medic Type"
                value={formData.medicType || ""}
                onChange={handleChange}
              >
                <option value="Registered Nurse">Registered Nurse</option>
                <option value="Certified Nurse Assistant">
                  Certified Nurse Assistant
                </option>
                <option value="Professional Nanny">Professional Nanny</option>
              </Select>
            </FormControl>
            <FormControl id="image" mb={3}>
              <FormLabel>Profile Image</FormLabel>
              <Input type="file" accept="image/*" onChange={handleFileChange} />
              {imageLoading && <Spinner size="sm" mt={2} />}
            </FormControl>
            <FormControl id="startDate" mb={3}>
              <FormLabel>Start date</FormLabel>
              <Input
                name="startDate"
                type="date"
                value={formData.startDate}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="endDate" mb={3}>
              <FormLabel>End date</FormLabel>
              <Input
                name="endDate"
                type="date"
                value={formData.endDate}
                onChange={handleChange}
              />
            </FormControl>

            <Text fontSize="lg" mt="6" mb="4" fontWeight="bold">
              More Details
            </Text>

            <Box mt="6" spacing={4} marginTop="20px" flex="1">
              <FormLabel>Marital Status</FormLabel>
              <Select
                color="black"
                name="maritalStatus"
                placeholder="Select Marital Status"
                value={formData.maritalStatus}
                onChange={handleChange}
              >
                {maritalStatuses.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </Select>
              {formData.maritalStatus === "Married" && (
                <Box mt={4}>
                  <FormLabel>Spouse's Full Name</FormLabel>
                  <Input
                    name="spouseFullName"
                    placeholder="Enter full name"
                    value={formData.spouseFullName}
                    onChange={handleChange}
                  />
                  <FormLabel mt={4}>Spouse's Phone Number</FormLabel>
                  <Input
                    name="spousePhoneNumber"
                    placeholder="Enter phone number"
                    value={formData.spousePhoneNumber}
                    onChange={handleChange}
                  />
                  <FormLabel mt={4}>Spouse's Email</FormLabel>
                  <Input
                    name="spouseEmail"
                    type="email"
                    placeholder="Enter email"
                    value={formData.spouseEmail}
                    onChange={handleChange}
                  />
                  <FormLabel mt={4}>Spouse's Home Address</FormLabel>
                  <Input
                    name="spouseHomeAddress"
                    placeholder="Enter home address"
                    value={formData.spouseHomeAddress}
                    onChange={handleChange}
                  />
                </Box>
              )}
            </Box>
            <Divider />

            <Text fontSize="lg" mt="6" mb="4" fontWeight="bold">
              Guarantor Details
            </Text>

            <FormControl isRequired marginTop="20px">
              <Box display="flex" marginBottom="20px">
                <Box flex="1" marginRight="10px">
                  <FormLabel>Guarantor's First Name</FormLabel>
                  <Input
                    name="guarantorFirstName"
                    placeholder="First Name"
                    value={formData.guarantorFirstName}
                    onChange={handleChange}
                    isRequired
                  />
                </Box>
                <Box flex="1" marginLeft="10px">
                  <FormLabel>Guarantor's Last Name</FormLabel>
                  <Input
                    name="guarantorLastName"
                    placeholder="Last Name"
                    value={formData.guarantorLastName}
                    onChange={handleChange}
                    isRequired
                  />
                </Box>
              </Box>

              <Box display="flex" marginBottom="20px">
                <Box flex="1" marginRight="10px">
                  <FormLabel>Guarantor's Email Address</FormLabel>
                  <Input
                    name="guarantorEmail"
                    placeholder="Email Address"
                    onChange={handleChange}
                    type="email"
                    value={formData.guarantorEmail}
                    isInvalid={emailErrors.guarantorEmailError}
                    isRequired
                  />
                  <FormErrorMessage>
                    {emailErrors.guarantorEmailError}
                  </FormErrorMessage>
                </Box>
                <Box flex="1" marginLeft="10px">
                  <FormLabel>Guarantor's Number</FormLabel>
                  <Input
                    name="guarantorPhone"
                    value={formData.guarantorPhone}
                    placeholder="Phone Number"
                    onChange={handleChange}
                    isRequired
                  />
                </Box>
              </Box>

              {/* Relationship with Guarantor Dropdown */}
              <Box marginBottom="20px">
                <FormLabel>Relationship with Guarantor</FormLabel>
                <Select
                  color="black"
                  name="relationshipWithGuarantor"
                  placeholder="Select Relationship"
                  onChange={handleChange}
                  value={formData.relationshipWithGuarantor}
                  isRequired
                >
                  <option value="Parent">Parent</option>
                  <option value="Sibling">Sibling</option>
                  <option value="Friend">Friend</option>
                  <option value="Spouse">Spouse</option>
                  <option value="Uncle/Aunt">Uncle/Aunt</option>
                  <option value="Cousin">Cousin</option>
                  <option value="Colleague">Colleague</option>
                  <option value="Mentor">Mentor</option>
                  <option value="Employer">Employer</option>
                  <option value="Other">Other</option>
                </Select>
              </Box>

              <FormLabel>Guarantor's Home Address</FormLabel>
              <Input
                name="guarantorHomeAddress"
                placeholder="Home Address"
                onChange={handleChange}
                marginBottom="20px"
                value={formData.guarantorHomeAddress}
                isRequired
              />
              <Text fontSize="lg" mt="6" mb="4" fontWeight="bold">
                Last Employer Details
              </Text>
              <Box marginBottom="20px">
                <FormLabel>Last Employer's Full Name</FormLabel>
                <Input
                  name="lastEmployerFullName"
                  value={formData.lastEmployerFullName}
                  placeholder="Full Name"
                  onChange={handleChange}
                  isRequired
                />
              </Box>

              <Box display="flex" marginBottom="20px">
                <Box flex="1" marginRight="10px">
                  <FormLabel>Phone Number</FormLabel>
                  <Input
                    name="lastEmployerPhoneNumber"
                    placeholder="Phone Number"
                    value={formData.lastEmployerPhoneNumber}
                    onChange={handleChange}
                    isRequired
                  />
                </Box>
                <Box flex="1" marginLeft="10px">
                  <FormLabel>Email</FormLabel>
                  <Input
                    name="lastEmployerEmail"
                    placeholder="Email Address"
                    onChange={handleChange}
                    value={formData.lastEmployerEmail}
                    type="email"
                    isInvalid={emailErrors.lastEmployerEmailError}
                    isRequired
                  />
                  <FormErrorMessage>
                    {emailErrors.lastEmployerEmailError}
                  </FormErrorMessage>
                </Box>
              </Box>

              <FormLabel>Employer/Hospital/Company Address</FormLabel>
              <Input
                name="lastEmployerAddress"
                placeholder="Address"
                onChange={handleChange}
                marginBottom="20px"
                isRequired
                value={formData.lastEmployerAddress}
              />
            </FormControl>
            <Button w="full" mt="5px" colorScheme="green" onClick={onCredOpen}>
              Update Credentials
            </Button>
            <Button
              w="full"
              mt="10px"
              colorScheme="blue"
              onClick={() => setPasswordModalOpen(true)}
            >
              Change Password
            </Button>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="outline"
              colorScheme="red"
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme="green"
              onClick={handleSubmit}
              isLoading={loading}
            >
              {loading ? <Spinner size="sm" /> : "Update"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <UpdateMedicCredentialsModal
        isOpen={isCredOpen}
        onClose={onCredClose}
        medic={customer}
      />
      {/* Password Change Modal */}
      <Modal size="sm" isOpen={isPasswordModalOpen} onClose={() => setPasswordModalOpen(false)}>
        <ModalOverlay />
        <ModalContent bg="#4B4B4B" color="white">
          <ModalHeader>Change Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="phoneNumber" mb={3}>
              <FormLabel>Phone Number</FormLabel>
              <Input name="phoneNumber" value={passwordData.phoneNumber} isDisabled />
            </FormControl>
            <FormControl id="newPassword" mb={3} isRequired>
              <FormLabel>New Password</FormLabel>
              <InputGroup>
                <Input
                  type={showNewPassword ? "text" : "password"}
                  name="newPassword"
                  value={passwordData.newPassword}
                  onChange={(e) =>
                    setPasswordData({
                      ...passwordData,
                      newPassword: e.target.value,
                    })
                  }
                />
                <InputRightElement onClick={() => setShowNewPassword(!showNewPassword)} cursor="pointer">
                  {showNewPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" colorScheme="red" mr={3} onClick={() => setPasswordModalOpen(false)}>
              Cancel
            </Button>
            <Button colorScheme="green" onClick={handlePasswordChange} isLoading={loading}>
              {loading ? <Spinner size="sm" /> : "Change Password"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditMedicModal;
