import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import AppointmentDetailsDrawer from "../../sections/admin/AppointmentDetailsDrawer";
import { baseUrl } from "../../../apiCalls/config";

const MatchedAppointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = () => {
    setSelectedAppointment(null); 
    onClose()
  };


  useEffect(() => {
    const fetchAppointments = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, redirecting to login.");
        window.location.href = "/login";
        return;
      }

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${baseUrl}/api/admin/matched-appointments`,
          config
        );

        const sortedAppointments = response.data.data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        setAppointments(sortedAppointments);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAppointments();
  }, []);

  const handleRowClick = (appointment) => {
    setSelectedAppointment(appointment);
    onOpen();
  };

  return (
    <Box maxH="400px" overflowY="auto">
      <Box bg="#4B4B4B" borderRadius="10px" p={4} color="white" w="100%">
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Text
            css={{
              position: "sticky",
              top: 0,
              background: "#4B4B4B",
              zIndex: 1,
            }}
            fontSize="md"
            fontWeight="bold"
          >
            Matched Customer Appointments
          </Text>
        </Flex>
        {loading ? (
          <Flex justifyContent="center" alignItems="center" h="200px">
            <Spinner size="xl" />
          </Flex>
        ) : appointments.length > 0 ? (
          <Table variant="simple" colorScheme="whiteAlpha">
            <Thead
              css={{
                position: "sticky",
                top: 0,
                background: "#4B4B4B",
                zIndex: 1,
              }}
            >
              <Tr>
                <Th color="purple.200">Recipient Name</Th>
                <Th color="purple.200">Service Plan</Th>
                <Th color="purple.200">Start Date</Th>
                <Th color="purple.200">Current Location</Th>
                <Th color="purple.200">Payment Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {appointments.map((appointment) => (
                <Tr
                  fontSize="13px"
                  _hover={{ bg: "#3B3B3B" }}
                  key={appointment.id}
                  onClick={() => handleRowClick(appointment)}
                  cursor="pointer"
                >
                  <Td>
                    {appointment.recipientFirstname}{" "}
                    {appointment.recipientLastname}
                  </Td>
                  <Td>{appointment.servicePlan}</Td>
                  <Td>
                    {new Date(
                      appointment.startDate
                    ).toLocaleDateString()}
                  </Td>
                  <Td  maxW="350px">{appointment.currentLocation}</Td>
                  <Td
                    color={
                      appointment.paid
                        ? "green.200"
                        : "red.300"
                    }
                  >
                    {appointment.paid ? "Paid" : "Not Paid"}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Text>No matched appointment at the moment</Text>
        )}
      </Box>
      

      {/* Drawer for displaying appointment details */}
      {selectedAppointment && (
        <AppointmentDetailsDrawer
          isOpen={isOpen}
          onClose={handleClose}
          appointment={selectedAppointment}
        />
      )}
    </Box>
  );
};

export default MatchedAppointments;
