import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  InputGroup,
  InputLeftElement,
  Th,
  Td,
  Center,
  Avatar,
  Spinner,
  useToast,
  Button,
  Menu,
  MenuButton,
  MenuList,
  Select,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import axios from "axios";
import { SearchIcon } from "@chakra-ui/icons";
import MedicDetailsDrawer from "./MedicDetailsDrawer ";
import { baseUrl } from "../../../apiCalls/config";

const getInitials = (name) => {
  const names = name.split(" ");
  const initials = names.map((n) => n[0]).join("");
  return initials.toUpperCase();
};

const UnverifiedMedics = () => {
  const [medics, setMedics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchEmail, setSearchEmail] = useState("");
  const [selectedMedic, setSelectedMedic] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); 
  const toast = useToast();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [showClearButton, setShowClearButton] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState({
    gender: "",
    religion: "",
    language: "",
    medicType: "",
    minAge: "",
    maxAge: "",
    minExperience: "",
    maxExperience: "",
  });

  const fetchMedics = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }
  
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      const response = await axios.get(
        `${baseUrl}/api/admin/medics/unverified`,
        config
      );
      const data = response.data.data.content.map((item) => ({
        id: item.id,
        name: `${item.bioData.firstName} ${item.bioData.lastName}`,
        phoneNumber: item.phoneNumber,
        preferredLanguage: item.preferredLanguage,
        gender: item.bioData.gender,
        medicType: item.medicType,
        image: item.bioData.image,
        fullDetails: item,
        yearsOfExp: item.yearsOfExp,
      }));
      setMedics(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching medics:", error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchMedics();
  }, []);

  useEffect(() => {
    if (searchEmail.trim() === "") {
      fetchMedics();
    }
  }, [searchEmail]);

  const handleSearch = async () => {
    if (!searchEmail.trim()) return;
  
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }
  
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      let response;
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
      // Check if search term is an email
      if (emailPattern.test(searchEmail)) {
        // Search by email
        response = await axios.get(
          `${baseUrl}/api/admin/medics/search?identifier=${searchEmail}`,
          config
        );
      } else {
        // Assume the search term is a recipient name (first name and last name)
        const [firstName, lastName] = searchEmail.split(" ");
        response = await axios.get(`${baseUrl}/api/admin/findByMedicName`, {
          params: {
            firstName: firstName || "",
            lastName: lastName || "",
          },
          ...config,
        });
      }
  
      // Extract and set the search results, assuming it might return an array
      const data = response.data.data;
      if (Array.isArray(data)) {
        // If it's an array of medics
        const medics = data.map((item) => ({
          id: item.id,
          name: `${item.bioData.firstName} ${item.bioData.lastName}`,
          phoneNumber: item.bioData.phoneNumber,
          preferredLanguage: item.preferredLanguage,
          gender: item.bioData.gender,
          medicType: item.medicType,
          image: item.bioData.image,
          fullDetails: item,
          yearsOfExp: data.yearsOfExp,
        }));
        setMedics(medics);
      } else if (data) {
        // If it's a single medic object
        const medic = {
          id: data.id,
          name: `${data.bioData.firstName} ${data.bioData.lastName}`,
          phoneNumber: data.bioData.phoneNumber,
          email: data.bioData.email,
          gender: data.bioData.gender,
          medicType: data.medicType,
          image: data.bioData.image,
          fullDetails: data,
          yearsOfExp: data.yearsOfExp,
        };
        setMedics([medic]);
      }
    } catch (error) {
      console.error("Error searching medic:", error);
    } finally {
      setLoading(false);
    }
  };  

  const openDrawer = (medic) => {
    setSelectedMedic(medic);
    setIsDrawerOpen(true);
  };

  const handleRowClick = (medic) => {
    openDrawer(medic.fullDetails);
  };

  const handleApplyFilters = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, redirecting to login.");
        window.location.href = "/login";
        return;
      }

     let activeFilters = Object.entries(selectedFilters).filter(
        ([_, value]) => value !== ""
      );
    
      if (activeFilters.length === 0) {
        fetchMedics(); 
        return;
      }

      let endpoint;
      let query;

      if (activeFilters.length === 1) {
        const [key, value] = activeFilters[0];
        const filterEndpoints = {
          gender: "gender",
          medicType: "medicType",
          language: "tribe",
          religion: "religion",
          minExperience: "experience",
          maxExperience: "experience",
          minAge: "age",
          maxAge: "age",
        };

        const selectedEndpoint = filterEndpoints[key] || "filter"; 
        endpoint = `${baseUrl}/api/admin/unverified/${selectedEndpoint}?${encodeURIComponent(
          selectedEndpoint
        )}=${encodeURIComponent(value)}`;
      } else {
        query = activeFilters
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join("&");

        endpoint = `${baseUrl}/api/admin/unverified/filter?${query}`;
      }
      const response = await axios.get(endpoint, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const data = response.data.data.content.map((item) => ({
        id: item.id,
        name: `${item.bioData.firstName} ${item.bioData.lastName}`,
        phoneNumber: item.bioData.phoneNumber,
        preferredLanguage: item.preferredLanguage,
        gender: item.bioData.gender,
        medicType: item.medicType,
        image: item.bioData.image,
        fullDetails: item,
        yearsOfExp: item.yearsOfExp,
      }));

      setMedics(data);
      setTotalElements(response.data?.data?.totalElements || 0);
      setShowClearButton(true);
      toast({
        title: "Filters applied",
        description: "Filtered results updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-left",
      });
    } catch (error) {
      toast({
        title: "Warning",
        description: "No result found",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-left",
      });
    } finally {
      setLoading(false);
      setIsFilterOpen(false);
    }
  };
  const handleClearFilters = () => {
    setSelectedFilters({
      gender: "",
      religion: "",
      language: "",
      medicType: "",
      minAge: "",
      maxAge: "",
      minExperience: "",
      maxExperience: "",
    });
  
    setShowClearButton(false); 
    setTotalElements(0);
    setIsFilterOpen(false); 
    fetchMedics(); 
  };
  
  return (
    <Box bg="#4B4B4B" borderRadius="10px" p={4} color="white" w="100%">
      <Flex  textAlign="left" justifyContent="space-between" alignItems="center" mb={4}>
        <Text fontSize="md" fontWeight="bold">
          Unverified Medics
        </Text>

        <Flex>
          {showClearButton && (
              <Flex alignItems="center">
              <Text mr={2}>Total: {totalElements}</Text>
              <Button colorScheme="red" onClick={handleClearFilters} mr={3}>
                Clear Filters
              </Button>
            </Flex>
          )}

          {/* Advanced Filter Button */}
          <Menu isOpen={isFilterOpen} onClose={() => setIsFilterOpen(false)}>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              Advanced Filter
            </MenuButton>
            <MenuList
              color="#4B4B4B"
              zIndex="1000"
              p={4}
              bg="white"
              borderRadius="10px"
              boxShadow="lg"
            >
              <Text mt={3} mb={2} fontSize={18}>
                Filter by
              </Text>

              <Text mb={2} fontSize={14}>
                Medic Type
              </Text>
              <Select
                bg="white"
                color="black"
                fontSize={12}
                value={selectedFilters.medicType}
                onChange={(e) =>
                  setSelectedFilters((prev) => ({
                    ...prev,
                    medicType: e.target.value,
                  }))
                }
              >
                <option value="">All</option>
                <option value="Registered Nurse">Registered Nurse</option>
                <option value="Certified Nurse Assistant">
                  Certified Nurse Assistant
                </option>
                <option value="Professional Nanny">Professional Nanny</option>
              </Select>

              <Text mb={2} fontSize={14}>
                Gender
              </Text>
              <Select
                bg="white"
                color="black"
                fontSize={12}
                value={selectedFilters.gender}
                onChange={(e) =>
                  setSelectedFilters((prev) => ({
                    ...prev,
                    gender: e.target.value,
                  }))
                }
              >
                <option value="">All</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Select>

              <Text mt={3} mb={2} fontSize={14}>
                Religion
              </Text>
              <Select
                bg="white"
                color="black"
                fontSize={12}
                value={selectedFilters.religion}
                onChange={(e) =>
                  setSelectedFilters((prev) => ({
                    ...prev,
                    religion: e.target.value,
                  }))
                }
              >
                <option value="">All</option>
                <option value="Christianity">Christianity</option>
                <option value="Islam">Islam</option>
                <option value="Traditional">Traditional</option>
                <option value="Other">Other</option>
              </Select>

              <Text mt={3} mb={2} fontSize={14}>
                Language
              </Text>
              <Select
                bg="white"
                color="black"
                fontSize={12}
                value={selectedFilters.language}
                onChange={(e) =>
                  setSelectedFilters((prev) => ({
                    ...prev,
                    language: e.target.value,
                  }))
                }
              >
                <option value="">All</option>
                <option value="English">English</option>
                <option value="Yoruba">Yoruba</option>
                <option value="Igbo">Igbo</option>
                <option value="Hausa">Hausa</option>
                <option value="Other">Other</option>
              </Select>

              <Flex mt={4} justifyContent="space-between">
                <Button colorScheme="blue" onClick={handleApplyFilters}>
                  Apply
                </Button>
                <Button colorScheme="gray" onClick={handleClearFilters}>
                  Clear
                </Button>
              </Flex>
            </MenuList>
          </Menu>
        </Flex>

      </Flex>
      <Flex mb={4}>
        <InputGroup>
          <Input
            placeholder="Search medic by name, or email"
            backgroundColor="#4B4B4B"
            color="white"
            borderRadius="10px"
            width="500px"
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSearch();
            }}
          />
          <InputLeftElement
            children={<SearchIcon color="white" />}
            pointerEvents="none"
          />
        </InputGroup>
        <Box ml={4}>
          <Text
            as="button"
            bg="#00C6F7"
            color="white"
            borderRadius="10px"
            fontSize="12px"
            p={2}
            onClick={handleSearch}
          >
            Search
          </Text>
        </Box>
      </Flex>
      {loading ? (
        <Flex justifyContent="center" alignItems="center" h="200px">
          <Spinner color="#00C6F7" />
        </Flex>
      ) : (
        <Box maxH="400px" overflowY="auto">
          <Table variant="simple" colorScheme="whiteAlpha">
            <Thead
              css={{
                position: "sticky",
                top: 0,
                background: "#4B4B4B",
                zIndex: 1,
              }}
            >
              <Tr>
                <Th color="purple.200">Avatar</Th>
                <Th color="purple.200">Name</Th>
                <Th color="purple.200">Phone Number</Th>
                <Th color="purple.200">Tribe</Th>
                <Th color="purple.200">Gender</Th>
                <Th color="purple.200">Medic Type</Th>
                <Th color="purple.200">Years of Experience</Th>
              </Tr>
            </Thead>
            <Tbody>
              {medics.map((medic, index) => (
                <Tr
                  fontSize="13px"
                  _hover={{ bg: "#3B3B3B" }}
                  key={index}
                  onClick={() => handleRowClick(medic)}
                  cursor="pointer"
                >
                  <Td>
                    {medic.image ? (
                      <Avatar
                        borderRadius="full"
                        boxSize="40px"
                        src={medic.image}
                        alt={medic.name}
                      />
                    ) : (
                      <Center
                        borderRadius="full"
                        boxSize="40px"
                        bg="white"
                        color="black"
                        fontWeight="bold"
                      >
                        {getInitials(medic.name)}
                      </Center>
                    )}
                  </Td>
                  <Td>{medic.name}</Td>
                  <Td>{medic.phoneNumber}</Td>
                  <Td>{medic.preferredLanguage}</Td>
                  <Td>{medic.gender}</Td>
                  <Td>{medic.medicType}</Td>
                  <Td>{medic.yearsOfExp}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
      {selectedMedic && (
        <MedicDetailsDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          medic={selectedMedic}
        />
      )}
    </Box>
  );
};

export default UnverifiedMedics;
