import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  InputGroup,
  InputLeftElement,
  Th,
  Td,
  Button,
  Drawer,
  DrawerBody,
  // DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  List,
  ListItem,
  Select,
} from "@chakra-ui/react";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { SearchIcon } from "@chakra-ui/icons";
import { baseUrl } from "../../../apiCalls/config";

const CustomPlansManagement = () => {
  const [customPlans, setCustomPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    fetchCustomPlans(page, size);
  }, [page, size]);

  const fetchCustomPlans = async (page, size) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${baseUrl}/appointment/all-custom-plan?page=${page}&size=${size}`,
        config
      );

      const data = response.data.data;
      setCustomPlans(data.content || []);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Error fetching custom plans:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatAmount = (amount) => {
    const num = Number(amount);
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleSearch = async () => {
    if (hasSearched) {
      setSearchQuery("");
      setHasSearched(false);
      fetchCustomPlans(page, size);
      return;
    }

    if (searchQuery.trim() === "") return;
    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${baseUrl}/api/admin/custom-plans/search?query=${searchQuery}`,
        config
      );
      setCustomPlans(response.data.data);
      setHasSearched(true);
    } catch (error) {
      console.error("Error searching custom plans:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value.trim() === "") {
      setHasSearched(false);
      fetchCustomPlans(page, size);
    }
  };

  const openModal = (plan) => {
    setSelectedPlan(plan);
    setIsDrawerOpen(true);
  };

  return (
    <Box bg="#4B4B4B" borderRadius="10px" p={4} color="white" w="100%">
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Text fontSize="md" fontWeight="bold">
          Custom Plans Management
        </Text>
        {/* Dropdown for selecting page size */}
        <Select
          width="120px"
          value={size}
          onChange={(e) => setSize(Number(e.target.value))}
          bg="white"
          color="black"
          fontSize="sm"
        >
          <option value="10">10 per page</option>
          <option value="25">25 per page</option>
          <option value="50">50 per page</option>
          <option value="100">100 per page</option>
        </Select>
      </Flex>

      <Flex mb={4}>
        <InputGroup>
          <Input
            placeholder="Search custom plans by name"
            backgroundColor="#4B4B4B"
            color="white"
            borderRadius="10px"
            width="500px"
            value={searchQuery}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSearch();
            }}
          />
          <InputLeftElement
            children={<SearchIcon color="white" />}
            pointerEvents="none"
          />
        </InputGroup>
        <Box ml={4}>
          <Text
            as="button"
            bg="#00C6F7"
            color="white"
            borderRadius="10px"
            fontSize="12px"
            p={2}
            onClick={handleSearch}
          >
            {hasSearched ? "Clear" : "Search"}
          </Text>
        </Box>
      </Flex>

      {loading ? (
        <Flex justifyContent="center" alignItems="center" h="200px">
          <ClipLoader color="#00C6F7" />
        </Flex>
      ) : (
        <Box maxH="400px" overflowY="auto">
          <Table variant="simple" colorScheme="whiteAlpha">
            <Thead
              css={{
                position: "sticky",
                top: 0,
                background: "#4B4B4B",
                zIndex: 1,
              }}
            >
              <Tr>
                <Th color="purple.200">Plan Name</Th>
                <Th color="purple.200">Duration (days)</Th>
                <Th color="purple.200">Shift</Th>
                <Th color="purple.200">Specialization</Th>
                <Th color="purple.200">Cost ₦</Th>
                <Th color="purple.200">Created On</Th>
              </Tr>
            </Thead>
            <Tbody>
              {customPlans.map((plan, index) => (
                <Tr
                  key={index}
                  fontSize="13px"
                  _hover={{ bg: "#3B3B3B" }}
                  cursor="pointer"
                  onClick={() => openModal(plan)}
                >
                  <Td>
                    {plan.name.split(" ").reduce((acc, word, index) => {
                      if (index % 3 === 0 && index !== 0)
                        acc.push(<br key={index} />);
                      acc.push(word + " ");
                      return acc;
                    }, [])}
                  </Td>
                  <Td>{plan.duration || "N/A"}</Td>
                  <Td>{plan.shift || "N/A"}</Td>
                  <Td>{plan.medicSpecialization || "N/A"}</Td>
                  <Td>{formatAmount(plan.costOfService) || "N/A"}</Td>
                  <Td>{new Date(plan.createdAt).toLocaleDateString()}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}

      {/* Pagination Controls */}
      <Flex justifyContent="space-between" alignItems="center" mt={4}>
        <Button
          colorScheme="blue"
          size="sm"
          onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
          isDisabled={page === 0}
        >
          Previous
        </Button>
        <Text>
          Page {page + 1} of {totalPages}
        </Text>
        <Button
          colorScheme="blue"
          size="sm"
          onClick={() => setPage((prev) => Math.min(prev + 1, totalPages - 1))}
          isDisabled={page >= totalPages - 1}
        >
          Next
        </Button>
      </Flex>

      {/* Custom Plan Details Modal */}
      {selectedPlan && (
         <Drawer isOpen={isDrawerOpen} placement="right" onClose={() => setIsDrawerOpen(false)} size="md">
         <DrawerOverlay />
         <DrawerContent bg="#4B4B4B" color="white">
           <DrawerCloseButton />
           <DrawerHeader>{selectedPlan.name} - Plan Details</DrawerHeader>
           <DrawerBody>
              <Text>
                <strong>Customer Name:</strong> {selectedPlan.customerName || "N/A"}
              </Text>
              <Text>
                <strong>Frequency:</strong> {selectedPlan.frequency || "N/A"}
              </Text>
              <Text>
                <strong>Duration (days):</strong> {selectedPlan.duration || "N/A"}
              </Text>
              <Text>
                <strong>Shift:</strong> {selectedPlan.shift || "N/A"}
              </Text>
              <Text>
                <strong>Specialization:</strong>{" "}
                {selectedPlan.medicSpecialization || "N/A"}
              </Text>
              <Text>
                <strong>Price:</strong> {formatAmount(selectedPlan.costOfService) || "N/A"}
              </Text>
              <Text>
              <Text>
                <strong>Cost of service:</strong> {formatAmount(selectedPlan.amountPayable) || "N/A"}
              </Text>
                <strong>Created On:</strong>{" "}
                {new Date(selectedPlan.createdAt).toLocaleDateString()}
              </Text>
              <Text fontWeight="bold" mt={2}>
                Selected Services:
              </Text>
              <List spacing={1}>
                {selectedPlan.selectedServices.map((service, i) => (
                  <ListItem key={i}>- {service}</ListItem>
                ))}
              </List>
              </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </Box>
  );
};

export default CustomPlansManagement;
