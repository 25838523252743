import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  extendTheme,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  Box,
} from "@chakra-ui/react";
import SelfAppointmentModal from "./SelfAppointmentForm";
import OthersAppointmentModal from "./OthersAppForm";
import BeneficiariesModal from "./Beneficiaries";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});

const BookAppointmentModal = ({ isOpen, onClose }) => {
  const [isSelfAppointmentModalOpen, setSelfAppointmentModalOpen] =
    useState(false);
  const [isBeneficiaryAppointmentModalOpen, setBeneficiaryAppointmentModalOpen] =
    useState(false);
  const [isBookAppointmentModalOpen, setBookAppointmentModalOpen] =
    useState(false);
  const [pages, setPages] = useState(null);

  // Function to close all modals
  const closeAllModals = () => {
    setSelfAppointmentModalOpen(false);
    setBeneficiaryAppointmentModalOpen(false);
    setBookAppointmentModalOpen(false);
  };

  // Effect to close any open modals when BookAppointmentModal mounts
  useEffect(() => {
    if (isOpen) {
      closeAllModals();
    }
  }, [isOpen]);

  // Function to open a modal while ensuring others are closed
  const handleOpenSelfAppointmentModal = (numPages) => {
    closeAllModals(); // Close other modals
    setPages(numPages);
    setSelfAppointmentModalOpen(true);
  };

  const handleOpenBeneficiaryAppointmentModal = (numPages) => {
    closeAllModals(); // Close other modals
    setPages(numPages);
    setBeneficiaryAppointmentModalOpen(true);
  };

  const handleOpenBookAppointmentModal = () => {
    closeAllModals(); // Close other modals
    setBookAppointmentModalOpen(true);
  };

  return (
    <Modal
      style={{ zIndex: 1400 }}
      blockScrollOnMount={false}
      theme={customTheme}
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: "sm", sm: "md", md: "lg" }}
    >
      <ModalOverlay />
      <ModalContent borderRadius="25px 25px 25px 0px">
        <ModalHeader
          fontFamily="heading"
          textAlign="center"
          color="#A210C6"
          fontWeight="bold"
        >
          Book appointment
        </ModalHeader>
        <ModalCloseButton color="#510863" />
        <ModalBody>
          <VStack spacing={4}>
            <Box
              onClick={() => handleOpenSelfAppointmentModal(2)}
              bg="linear-gradient(80deg, #A210C6, #E552FF)"
              color="white"
              fontWeight="bold"
              borderRadius="8px"
              cursor="pointer"
              p="2"
              textAlign="center"
              fontFamily="body"
            >
              Book for yourself
            </Box>
            <Box
              onClick={handleOpenBookAppointmentModal}
              bg="linear-gradient(80deg, #A210C6, #E552FF)"
              color="white"
              fontWeight="bold"
              borderRadius="8px"
              cursor="pointer"
              p="2"
              textAlign="center"
              fontFamily="body"
            >
              Book for a beneficiary
            </Box>
            <Box
              onClick={() => handleOpenBeneficiaryAppointmentModal(3)}
              bg="linear-gradient(80deg, #A210C6, #E552FF)"
              color="white"
              fontWeight="bold"
              borderRadius="8px"
              cursor="pointer"
              p="2"
              textAlign="center"
              fontFamily="body"
              marginBottom="20px"
            >
              Book for others
            </Box>
          </VStack>
        </ModalBody>
        {isSelfAppointmentModalOpen && (
          <SelfAppointmentModal
            isOpen={isSelfAppointmentModalOpen}
            onClose={() => setSelfAppointmentModalOpen(false)}
            pages={pages}
          />
        )}
        {isBeneficiaryAppointmentModalOpen && (
          <OthersAppointmentModal
            isOpen={isBeneficiaryAppointmentModalOpen}
            onClose={() => setBeneficiaryAppointmentModalOpen(false)}
            pages={pages}
          />
        )}
        {isBookAppointmentModalOpen && (
          <BeneficiariesModal
            isOpen={isBookAppointmentModalOpen}
            onClose={() => setBookAppointmentModalOpen(false)}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

export default BookAppointmentModal;
